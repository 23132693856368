import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsHttpService } from '@qaroni-core/services/comms/errors-http/errors-http.service';

@Component({
  standalone: true,
  selector: 'qaroni-errors-http-dialog',
  templateUrl: './errors-http-dialog.component.html',
  styleUrls: ['./errors-http-dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class ErrorsHttpDialogComponent {
  public showErrorDetails = false;

  public dialogRef = inject(MatDialogRef<ErrorsHttpDialogComponent>);
  public error: HttpErrorResponse = inject(MAT_DIALOG_DATA);
  private errorsHttp = inject(ErrorsHttpService);

  public onShowErrorDetails(): void {
    this.showErrorDetails = true;
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  get isControlledError(): boolean {
    return this.errorsHttp.isControlledError(this.error);
  }
}
