import { Routes } from '@angular/router';
import { authenticationGuard } from '@qaroni-core/config/guards/authentication.guard';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  {
    path: 'auth',
    loadChildren: () => import('@qaroni-app/application/auth/auth.routes'),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@qaroni-app/application/dashboard/dashboard.routes'),
    canLoad: [authenticationGuard],
  },
  {
    path: 'pages',
    loadChildren: () => import('@qaroni-app/application/pages/pages.routes'),
  },
  {
    path: '',
    loadChildren: () => import('@qaroni-app/application/views/views.routes'),
  },
];
